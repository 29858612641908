import React from "react"
import Layout from '../components/layout'
import Head from '../components/head'
import Git from '../images/github.png'
import Linked from '../images/linked.png'
import { Spring } from 'react-spring/renderprops'
import projectStyle from './projects.module.scss'
const divStyle = {
    marginLeft: `2.5%`,
    borderRadius: `15%`

  };
  const first = {
    borderRadius: `15%`

  };

const ContactPage = () => {
    return (
        <div>
            <Layout>
            <Head title="Contact"/>
            <h1>Contact</h1>
            <Spring
                from={{ opacity: 0 }}
                to={{ opacity: 1 }}>
                {props => <div style={props}>

                <p>Email: ethanwu18@gmail.com</p>
            
            <a href="https://github.com/ethanwu18" target="_blank" rel="noopener noreferrer">
                <img src={Git} alt="Click Here to Go to GitHub" width="10%" height="10%" className={projectStyle.indexBtn} style={first}></img>
             </a>
            
            
            <a href='https://www.linkedin.com/in/ethan-wu-8bba22109/' target='_blank' rel="noopener noreferrer">
                <img src={Linked} alt="Click Here to Go to LinkedIn" width="10%" height="10%" className={projectStyle.indexBtn} style={divStyle}></img>
            </a>

                </div>}
            </Spring>
            
            </Layout>
        </div>
    )
}

export default ContactPage